import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import { CircularProgress, Link, Button, MenuItem, Stack, InputLabel, Select, FormControl, Paper } from '@mui/material';

import CodeMirror from '@uiw/react-codemirror';

import data from './data/examples.json';

const color = '#FE9B27';

class App extends Component {
  constructor(props) {
    super(props);
     this.state = {
        example: 0,
        codeExampleNames: data.examples.map(example => example.title),
        codeExamples: data.examples.map(example => example.rules.join("\n")),
        code: '',
        result: '',
        buttonDisabled: false
      }
  }

  runQuery() {
    var config = {
      method: 'POST',
      url: '/api/query',
      headers: {
        'Content-Type': 'application/json',
      },
      data : {query: this.state.code}
    };

    var resultMessage = ''

    this.setState({buttonDisabled: true}, () => {
    axios(config)
    .then((response) =>
      resultMessage = response.data
    )
    .catch(function(error) {
       console.log(error);
       resultMessage = error.message;
    }).finally(() => this.setState({result: resultMessage, buttonDisabled: false})  )});
  }

  componentDidMount() {
     this.setState({code : this.state.codeExamples[0]}, () => this.runQuery());
  }

  render() {
    return (
        <div className='App'><Stack style={{marginLeft: 15, marginTop: 20, marginBottom: 10}} direction="row" spacing={5}>
          {/* Header */}
          <div style={{fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', fontWeight: 200, fontSize: 25, color:color}} >Datalog Tool   🥧</div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small" style={{color: color}}>Examples</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={this.state.example}
              label="Examples"
              sx={{

                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: color,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: color,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: color,
                },
              }}
              onChange={(event) => { this.setState({example: event.target.value, code: this.state.codeExamples[event.target.value]}, () => this.runQuery()) }}
            >
              {this.state.codeExampleNames.map((name, index) => {
                return <MenuItem key={index} value={index}>{name}</MenuItem>
              })}
            </Select>
          </FormControl>
          </Stack>
         {/* Query */}
          <Paper style={{display: 'flex', flexDirection: 'column-reverse', maxHeight: '50vh', overflow: 'auto'}}>
          <CodeMirror
            value={this.state.code}
            options={{
              theme: 'monokai',
              tabSize: 1,
              keyMap: 'sublime',
              mode: 'jsx',
            }}
            onChange={(newValue) => { this.setState({code: newValue})}}
            style={{ width: 'calc(100% - 20px' , margin: '10px', border: 0 }}
          />
         </Paper>
          <Stack style={{margin: '10px'}} direction="row" spacing={2}>
             {/* Execute Button */}
             <Button style={{borderColor: color, color: color}} variant="outlined" disabled={this.state.buttonDisabled} onClick={() => this.runQuery()}>Execute</Button>
             {this.state.buttonDisabled ? <CircularProgress style={{'color': color}} size="2rem"/> : ''}
          </Stack>
          {/* Result */}
          <Paper  style={{whiteSpace: 'pre', minHeight: '40px', overflow: 'auto', padding: '10px'}}>
            <div style={{fontFamily: 'monospace'}}>
              {this.state.result}
            </div>
          </Paper>
          {/* Footer */ }
          <Stack style={{margin: '10px', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: 15, color:color}} direction="row">
            <div style={{marginRight: '5px'}}  >Based on</div>
            <Link href="https://souffle-lang.github.io/" color="inherit">{'Soufflé'}</Link>
          </Stack>
          <Stack style={{margin: '10px', fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif', fontSize: 15, color:color}} direction="row">
            <div style={{marginRight: '5px'}}>Hosted by TUM,</div><Link href="https://www.db.in.tum.de/" color="inherit">Chair for Database Systems</Link>
          </Stack>
          <Stack style={{margin: '10px', fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif', fontSize: 15, color:color}} direction="row">
            <div style={{marginRight: '5px'}}>Maintainer:</div><Link href="mailto:i3erdb@in.tum.de" color="inherit">i3erdb@in.tum.de</Link>
          </Stack>
        </div>
    );
  }
}
export default App;
